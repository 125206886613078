import { createStore } from 'vuex'

// Create a new store instance.
export default createStore({
    state() {
        return {
            attrs: []
        }
    },
    mutations: {
        addAttr(state, attrs) {
            state.attrs = attrs
        }
    }
})