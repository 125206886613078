import { ref } from 'vue'
import { useStore } from 'vuex'
const getStartNum = (dateBase, dateStart, _round = 8) => {
    const durSec = dateStart.getTime() - dateBase.getTime()

    return Math.round(durSec / (1000 * 3600 * 24)) % _round
}
const classes = [
    ['smena-1', 'smena-2', 'smena-3', 'smena-4', 'smena-5', 'smena-6', 'smena-7', 'smena-8', ],
    ['smena-1', 'smena-4', 'smena-6', 'smena-7', ],
    ['smena-1', 'smena-2', 'smena-6', 'smena-7', ],
]

const getDots = page => {
    let dots = []
    const dateBase = new Date(2021, 6, 26)
    for (let i = 1; i < 32; i++) {
        const currDate = new Date(page.year, page.month - 1, i)
        const durSec = currDate.getTime() - dateBase.getTime()
        const s = Math.round(durSec / (1000 * 3600 * 24)) % 4
        if (s < 2) {
            dots.push(currDate)
        }
    }
    console.log(dots)
    return dots
}
export default function dates() {
    const base = [
            { date: new Date(2021, 6, 26), r: 8 },
            { date: new Date(2020, 11, 30), r: 4 },
            { date: new Date(2021, 6, 26), r: 4 },
        ]
        // const dateEnd = new Date(age.year, page.month - 1)


    const attrs = ref([])
    const store = useStore()
    const dateAdd = (page, bd) => {
        attrs.value = []
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth();
        const d = date.getDate();
        let _round = base[bd].r
        const dateStart = new Date(page.year, page.month - 1, 1)
        let idx = getStartNum(base[bd].date, dateStart, _round)
        attrs.value = []
        for (let i = 1; i < 32; i++) {
            const td = new Date(year, month, d)
            const d1 = new Date(page.year, page.month - 1, i)
            let tmp = {
                highlight: {
                    class: classes[bd][idx % _round],
                    contentClass: classes[bd][idx % base[bd].r] + '-text',

                },
                dates: d1,
            }
            if (td.getTime() == d1.getTime()) {
                tmp.highlight.contentStyle = { color: 'red' }
                tmp.highlight.style = { border: 'red solid 1px' }
            }
            attrs.value.push(tmp)
            idx += 1
        }
        if (bd == 0) {
            const dots = getDots(page)
            attrs.value.push({
                dot: {
                    color: 'red',
                },
                dates: dots,
            }, )
        }



    }
    store.commit('addAttr', attrs)
    return { attrs, dateAdd }
}
