<template>
    <div class="container">
    <div class="r-buttons">
      <input
        type="radio"
        name="user"
        id="vp"
        v-model="base"
        value="0"
        checked
      />
      ВП
      <input
      type="radio"
      name="user"
      id="ip"
      v-model="base"
      value="1" />
      ИП
    </div>
    <div>
      <Calendar
        :attributes="attrs"
        :min-date="new Date(2021, 7)"
        @update:to-page="to"
      />
    </div>
  </div>
</template>

<script>
import { Calendar } from "v-calendar";
import { ref, watch } from "vue";
import dates from "../helpers/setDate";
import { useRoute, useRouter } from 'vue-router'

export default {
  name: "App",
  components: {
    Calendar,
  },

  setup() {
    const route = useRoute()
    const router = useRouter()
    const base = ref(0);
    if(route.query.usr === "1"){
      base.value = 1
    }
    let page = ref({});
    const a = ref(0);
    const { attrs, dateAdd } = dates(base);
    const to = (e) => {
      page.value = e;
      dateAdd(page.value, base.value);
    };
    watch(base, (base) => {

      router.push({ path: '/', query: { usr: base } })
      dateAdd(page.value, base);
    });
    return {
      page,
      base,
      to,
      attrs,
      a,
    };
  },
};
</script>

<style>

.r-buttons {
    display: flex;
    margin: 10px 0;
    justify-content: center;
    align-items: center;
    min-height: 30px;
    font-size: 26px;
    font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    font-weight: 700;
}

.r-buttons input {
    margin: 0 10px 0 20px;
}

.vc-container,
.vc-container * {
    /* box-sizing: border-box !important; */
}
.vc-dot {
    width: 10px !important;
    height: 10px !important;
}
.vc-header {
    min-height: 50px !important;
    padding: 0 !important;
}

.vc-title {
    font-size: 36px !important;
}

.vc-day {
    min-height: 70px !important;
}

.vc-day-content {
    font-size: 40px !important;
}

.vc-highlight {
    width: 80% !important;
    height: 80% !important;
}

.container {
    margin: 0 auto;
    text-align: center;
}

.vc-container {
    width: 80%   !important;
    max-width: 660px !important;
}

.smena-1,
.smena-2,
.smena-3,
.smena-4,
.smena-5,
.smena-6,
.smena-7,
.smena-8 {
    --rounded-full: 15%;
    border: 1px solid #dedede;
}

.smena-1,
.smena-2 {
    background: linear-gradient(90deg, #f17f13, #f17f13, #fff, #fff);
}

.smena-4 {
    background: linear-gradient(90deg, #fff, #fff, #043d7e, #043d7e);
}

.smena-5 {
    background: linear-gradient( 90deg, #043d7e, #fff, #fff, #043d7e, #043d7e, #043d7e);
}

.smena-6 {
    background: linear-gradient(90deg, #043d7e, #fff, #fff, #fff);
}

.smena-1-text,
.smena-2-text,
.smena-4-text,
.smena-5-text,
.smena-6-text {
    /* color:#fff; */
    text-shadow: rgb(255, 255, 255) 2px 2px 0, rgb(255, 255, 255) -2px -2px 0, rgb(255, 255, 255) -2px 2px 0, rgb(255, 255, 255) 2px -2px 0;
}

@media (max-width: 800px) {
    .vc-header {
        min-height: 40px !important !important;
        padding: 0 !important;
    }
    .vc-title {
        font-size: 30px !important;
    }
    .vc-day {
        min-height: 65px !important;
    }
    .vc-day-content {
        font-size: 36px !important;
    }
    .vc-highlight {
        width: 90% !important;
        height: 90% !important;
    }
}

@media (max-width: 660px) {
    .vc-header {
        min-height: 40px !important;
        padding: 0 !important;
    }
    .vc-title {
        font-size: 26px !important;
    }
    .vc-day {
        min-height: 60px !important;
    }
    .vc-day-content {
        font-size: 30px !important;
    }
    .vc-highlight {
        width: 90% !important;
        height: 90% !important;
    }
}

@media (max-width: 550px) {
    .smena-1-text,
    .smena-2-text,
    .smena-4-text,
    .smena-5-text,
    .smena-6-text {
        /* color:#fff !important; */
        text-shadow: rgb(255, 255, 255) 1px 1px 0, rgb(255, 255, 255) -1px -1px 0, rgb(255, 255, 255) -1px 1px 0, rgb(255, 255, 255) 1px -1px 0 !important;
    }
    .vc-header {
        min-height: 30px !important;
        padding: 0 !important;
    }
    .vc-title {
        font-size: 20px !important;
    }
    .vc-day {
        min-height: 50px !important;
    }
    .vc-day-content {
        font-size: 26px !important;
    }
    .vc-highlight {
        width: 90% !important;
        height: 90% !important;
    }
}

@media (max-width: 440px) {
    .vc-header {
        min-height: 20px !important;
        padding: 0 !important;
    }
    .vc-title {
        font-size: 16px !important;
    }
    .vc-day {
        min-height: 40px !important;
    }
    .vc-day-content {
        font-size: 20px !important;
    }
    .vc-highlight {
        width: 100% !important;
        height: 100% !important;
    }
}

</style>
